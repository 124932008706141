import React from 'react';
// import PropTypes from 'prop-types';
import './MissingPage.css';

const MissingPage = () => {
  return (
    <div>Missing</div>
  );
};

MissingPage.propTypes = {};

export default MissingPage;
